.test {
    margin: 2em;
    padding: 0.5em;
    border: 2px solid #000;
    font-size: 2em;
    text-align: center;
}

.tree {
    display: flex;
    /* justify-content: space-around; */
    /* align-items: center; */
    flex-direction: column;
    width: "100%"
}

.tree__column {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    width: "100%";
    margin: 80px 0px;
}

.svg_container {
    position: absolute;
}