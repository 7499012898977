.view {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 0 5px #BCBCBC;
    padding: 20px;
    margin-right: 2px;
    flex: 1
}

.empty {

    border: 4px dotted #BCBCBC;
    border-radius: 5px;
    margin-right: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48pt;
    color: #BCBCBC;
    flex: 1
}

.add_card:hover {
    cursor: pointer;
}